<template>
  <div class="layout" :class="[dark]">
    <slot />
  </div>
</template>

<script setup>
import { useStorage } from "@vueuse/core";

const dark = useStorage("picsellia-theme", "light");
</script>

<style scoped>
.layout {
  height: 100%;
  width: 100%;
}
</style>
